.header {
	position: relative;
	z-index: 999;
	&__nav {
		background: $dop-color;
		padding: 13px 0;
		ul {
			display: flex;
			justify-content: flex-start;
			li {
				margin-right: 25px;
				font-size: 0.875rem;
				line-height: 0.95rem;
				&:last-of-type {
					margin-right: 0;
				}
				a {
					color: $secondary-color;
					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}
	&__info {
		padding: 25px 0;
		&__inner {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
		&__logo {
			a {
				display: flex;
				align-items: center; 
			}
			img {
				width: 100%;
			}
		}
		&__content {
			.btn {
				font-size: 1.1rem;
				padding: 10px 30px;
				&:first-of-type {
					margin-right: 10px;
				}
			}
		}
	}
	&__category {
		border-top: 1px solid $dop-color;
		border-bottom: 1px solid $dop-color;
		&__inner {
			position: relative;
			display: flex;
			padding: 15px 0;
		}
		&__all {
			&-title {
				a {
					color: $dop-color;
					font-weight: 700;
					position: relative;
					&::before {
						content: '';
						position: absolute;
						width: 6px;
						height: 6px;
						top: 50%;
						right: -10px;
						background: $dop-color;
						border-radius: 100%;
						transform: translateY(-25%);
					}
					&:hover {
						color: lighten($dop-color, 15%);
						&::before {
							background: transparent;
							border: 3px solid transparent;	
							border-top: 3px solid $dop-color;
							border-radius: 0;
							transform: translateY(0);
						}
					}
				}
			}
			&-list {
				display: none;
				position: absolute;
				left: 0;
				top: 100%;
				padding: 5px 0;
				background: $secondary-color;
				border: 1px solid #bbb;
				box-shadow: 1px 1px 10px #999;
				z-index: 999;
				ul {
					width: 200px;
					li {
						position: relative;
						padding: 5px 15px;
						font-size: 0.8125rem;
						&:hover {
							background: #f7f7f7;
							.header__category__sub-list {
								display: block;
							}
						}
						a {
							display: block;
							overflow: hidden;
							white-space: nowrap;
							text-overflow: ellipsis;
						}
					}
				}
			}
		}
		.category-list_open {
			display: block;
		} 
		&__sub-list {
			display: none;
			position: absolute;
			left: 100%;
			top: 0;
			padding: 5px 0;
			background: $secondary-color;
			border: 1px solid #bbb;
			box-shadow: 1px 1px 10px #999;
			z-index: 999;
		}
		&__main {
			flex-grow: 1;
			margin-left: 45px;
			ul {
				display: flex;
				justify-content: space-between;
				li {
					a {
						position: relative;
						&::before {
						    content: '';
						    visibility: hidden;
						    position: absolute;
						    left: 0;
						    right: 0;
						    bottom: -5px;
						    height: 1px;
						    background-color: $base-color;
						    transform: scale(0);
						    transition: all .2s ease-out;
						}
						&:hover {
							&::before {
							    visibility: visible;
								transform: scale(1);
							}
						}
					}
				}
			}
		}
		&__mob-buttons {
			display: none;
		}
	}
	&_search {
		.header {
			&__info {
				&__search {
					padding: 0 20px;
					flex-grow: 1;
					.form {
						margin: 0 auto;
						width: 60%;
						&_open {
							width: 80%;
							transition: all 0.2s ease-out;
							.form {
								&__field {
									.input {
										border-radius: 14px;
										border: 1px solid #707070;
									}
								}
							}
						}
						&__field {
							position: relative;
							.input {
								padding: 0 40px 0px 15px;
								height: 40px;
								border-radius: 0;
								border-bottom: 1px solid #707070;
								&::placeholder {
									color: #929292;
									font-weight: 300;
								}
							}
							.btn {
								padding: 0;
								top: 50%;
								right: 15px;
								transform: translateY(-50%);
								position: absolute;
								&:hover {
									.icon {
										color: $effect-color;
										transition: 0.2s;
									}
								}
							}
							.icon {
								width: 24px;
								height: 24px;
							}
						}
					}
				}
			}
		}
	}
}

@include md-up {
	.header {
		&__nav {
			&__tablet-logo {
				display: none;
			}
			.icon-search-js {
				display: none;
			}
			&__burger {
				display: none;
			}
			&__mob-logo {
				display: none;
			}
		}
		&__category {
			&__tablet-buttons {
				display: none;
			}
		}
		&_search {
			.header {
				&__nav {
					&__search {
						display: none;
					}
				}
			}
		}
	}
}

@include md-down {
	.header {
		&__nav {
			padding: 15px 0;
			background: $secondary-color;
			&__inner {
				display: flex;
				justify-content: space-between;
				align-items: center;
			}
			nav {
				display: none;
				position: absolute;
				top: 150%;
				right: 0;
				padding: 10px 5px 10px 15px;
				background: #ffffff;
				border: 1px solid #bbb;
				box-shadow: 1px 1px 10px #999;
				z-index: 999;
			}
			ul {
				flex-direction: column;
				li {
					margin-bottom: 10px;
					&:last-of-type {
						margin-bottom: 0;
					}
					a {
						color: $base-color;
					}
				}
			}
			&__burger {
				position: relative;
				width: 35px;
				height: 25px;
				display: flex;
				flex-direction: column;
				cursor: pointer;
				span {
					display: inline-block;
					position: absolute;
					right: 0;
					height: 3px;
					width: 35px;
					background: $dop-color;
					transition: .25s ease-in-out;
					&:nth-of-type(1) {
						top: 0;
					}
					&:nth-of-type(2) {
						top: 50%;
					}
					&:nth-of-type(3) {
						top: 100%;
					}
				}
			}
			.burger_open {
				span {
					top: 50%;
					&:nth-of-type(1) {
						transform: rotate(45deg);
					}
					&:nth-of-type(2) {
						display: none;
					}
					&:nth-of-type(3) {
						transform: rotate(-45deg);
					}
				}
			}
		}
		&__info {
			display: none;
		}
		&__category {
			&__inner {
				justify-content: space-between;
				align-items: center;
			}
			&__all {
				&-title {
					a {
						font-size: 1.125rem;
						&::before {
							right: -15px;
						}
						&:hover {
							&::before {
								border: 4px solid transparent;
								border-top: 4px solid $dop-color;
							}
						}
					}
				}
				&-list {
					ul {
						overflow-y: auto;
						max-height: 100px;
						li {
							&:hover {
								.header__category__sub-list {
									display: none;
								}
							}
						}
					}
				}
			}
			&__main {
				display: none;
			}
			&__tablet-buttons {
				display: none;
			}
			&__mob-buttons {
				display: flex;
				align-items: center;
				.btn {
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 0;
					&_info {
						margin-right: 10px;
						width: 115px;
						height: 39px;
						font-size: 0.75rem;
					}
					&_primary {
						width: 39px;
						height: 39px;
						&:hover {
							background: darken(#ffffff, 5%);
							border-color: darken(#ffffff, 5%);
						}
					}
				}
			}
		}
		&_search {
			.header {
				&__nav {
					.icon-search-js {
						position: absolute;
						top: 0;
						right: 80px;
						width: 27px;
						height: 27px;
						cursor: pointer;
						&:hover {
							color: $effect-color;
							transition: 0.2s;
						}
					}
					&__search {
						opacity: 0;
						visibility: hidden;
						position: absolute;
						top: 100%;
						left: 0;
						width: 100%;
						height: 68px;
						background: $secondary-color;
						box-shadow: 6px 10px 14px 4px rgba(0, 0, 0, 0.15);
						z-index: 250;
						transition: all 0.2s ease-out;
						.form {
							display: flex;
							align-items: center;
							&__field {
								flex-grow: 1;
								.input {
									padding: 20px 17px;
									&::placeholder {
										color: #929292;
										font-weight: 300;
									}
								}
							}
							.btn {
								padding: 0;
								margin-right: 15px;
								width: 109px;
								height: 34px;
								color: #fefefe;
								font-size: 1.1rem;
								font-weight: 400;
								background: $effect-color;
								border-radius: 17px;
							}
						}
					}
					.active {
						opacity: 1;
						visibility: visible;
						top: 158%;
					}
				}
			}
		}
	}
}
@include sm-down {
	.header {
		&__category {
			&__all {
				&-title {
					a {
						font-size: 0.875rem;
					}
				}
			}
			&__tablet-buttons {
				.btn {
					padding: 10px 15px;
					&_info {
						margin-right: 10px;
					}
				}
			}
		}
		&_search {
			.header {
				&__nav {
					.icon-search-js {
						position: absolute;
						top: 0;
						right: 80px;
						width: 27px;
						height: 27px;
						&:hover {
							color: $effect-color;
							transition: 0.2s;
						}
					}
					&__search {
						.form {
							&__field {
								.input {
									font-size: 0.85rem;
								}
							}
							.btn {
								width: 90px;
								font-size: 0.875rem;
							}
						}
					}
					.active {
						opacity: 1;
						visibility: visible;
						top: 158%;
					}
				}
			}
		}
	}
}