.profile {
  padding: 50px 0 65px 0;
  &__title {
    margin-top: 40px;
    font-size: 1.5rem;
  }
  &__inner {
    display: flex;
    justify-content: space-between;
  }
  &__content {
    width: 76%;
  }
  &__main {
    display: flex;
    padding: 30px 25px 40px 25px;
    box-shadow: 0 0 7px 1px rgba(0, 0, 0, 0.03);
    &__cap {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
      h2 {
        font-size: 1.5rem;
      }
      &__prev {
        display: flex;
        align-items: center;
        img {
          width: 32px;
          height: 32px;
        }
        span {
          margin-left: 10px;
          font-size: 1.1em;
        }
      }
    }
    &__img-wrapper {
      width: 20%;
      text-align: center;
    }
    &__info-wrapper {
      width: 80%;
      padding-left: 30px;
    }
    &__about {
      font-size: 1.125rem;
      font-weight: 700;
    }
    &__date-registr {
      margin: 7px 0 12px 0;
      font-size: 0.75rem;
    }
    &__rep {
      margin-top: 30px;
      span {
        color: #006f0a;
      }
    }
    &__reviews {
      margin-top: 15px;
    }
  }
}

@include md-up {
  .profile {
    &__main {
      &__info-wrapper {
        &__views {
          display: none;
        }
      }
      &__cap {
        &__prev {
          display: none;
        }
      }
    }
  }
}

@include md-down {
  .profile {
    padding: 20px 0 50px 0;
    &__content {
      width: 100%;
    }
    &__main {
      padding: 20px 25px 25px 25px;
      &__img-wrapper {
        width: 35%;
      }
      &__info-wrapper {
        width: 65%;
        padding-left: 20px;
        &__views {
          margin: 15px 0;
          .icon {
            width: 16px;
            height: 10px;
          }
        }
      }
      &__about {
        font-size: 1.125rem;
      }
      &__reviews {
        margin-top: 10px;
      }
    }
  }
}