.object-det {
  margin-top: 20px;
  &__inner {
    display: flex;
    justify-content: space-between;
  }
  &__content {
    width: 74%;
  }
  &__items {
    &__buttons-wrapper {
      margin-top: 35px;
      text-align: center;
    }
  }
  &__item {
    padding: 25px 30px 35px 40px;
    box-shadow: 0 0 7px 1px rgba(0, 0, 0, 0.03);
    &__dop {
      display: none;
    }
    &__main {
      display: flex;
    }
    &__img-wrapper {
      img {
        width: 76px;
        height: 82px;
      }
    }
    &__about {
      display: flex;
      padding-left: 25px;
      &__info {
        margin-right: 45px;
        h3 {
          margin-bottom: 7px;
          font-size: 1.125rem;
          font-weight: 700;
          a {
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
      &__date-registr {
        margin-bottom: 12px;
        font-size: 0.75rem;
      }
      &__rep {
        display: inline-block;
        margin-right: 45px;
        span {
          color: #006f0a;
        }
      }
      &__reviews {
        display: inline-block;
      }
      &__views {
        margin-top: 35px;
        width: 100%;
        .icon {
          width: 16px;
          height: 10px;
        }
      }
    }
    &__feedback {
      margin-top: 20px;
      h5 {
        margin-bottom: 20px;
        font-size: 1.125rem;
        font-weight: 700;
      }
      p {
        margin-bottom: 25px;
      }
    }
    &__estimate {
      display: flex;
      font-size: 1.125rem;
      font-weight: 700;
      ul {
        margin-top: 12px;
        li {
          margin-bottom: 5px;
          font-size: 0.85rem;
          font-weight: 400;
        }
      }
      &__adv {
        margin-right: 100px;
        color: #087400;
      }
      &__disadv {
        color: #a81b1b;
      }
    }
  }
}

@include md-up {
  .object-det {
    &__reviews-wrapper {
      display: none;
    }
  }
}

@include md-down {
  .object-det {
    &__content {
      width: 100%;
      margin-bottom: 30px;
    }
    &__reviews {
      &__head {
        h3 {
          font-size: 1.875rem;
        }
      }
    }
    &__item {
      padding: 20px 12px 30px 18px;
      &__about {
        &__info {
          h3 {
            font-size: 1rem;
          }
        }
        &__dop {
          font-size: 0.875rem;
        }
        &__rep {
          display: block;
          margin-bottom: 8px;
        }
        &__reviews {
          display: block;
        }
        &__views {
          margin-top: 10px;
        }
      }
      &__feedback {
        p {
          font-size: 0.875rem;
        }
      }
    }
  }
}

@include sm-down {
  .object-det {
    &__items {
      &__buttons-wrapper {
        display: none;
      }
    }
    &__item {
      padding: 20px 5px 60px 10px;
      &__about {
        &__info {
          margin-right: 0;
        }
        &__dop {
          display: none;
        }
      }
      &__dop {
        display: flex;
        margin-top: 15px;
        font-size: 0.875rem;
        &__rep {
          margin-right: 20px;
          span {
            color: #006f0a;
          }
        }
        &__views {
          margin-top: 14px;
          width: 100%;
          .icon {
            width: 16px;
            height: 10px;
          }
        }
      }
      &__feedback {
        margin-top: 30px;
      }
      &__estimate {
        flex-direction: column;
        &__adv {
          margin-right: 0;
        }
        &__disadv {
          margin-top: 30px;
        }
      }
    }
  }
}

.obj-cm {
  padding-bottom: 40px;
  &__add {
    margin-bottom: 60px;
    .add-cm__desc__field {
      margin-bottom: 0px;
      max-width: 630px;
    }
    .add-cm__desc__action {
      margin-top: 10px;
      .btn {
        font-size: 1rem;
      }
    }
  }
  .object__reviews__item {
    padding: 15px 10px 15px 20px;
    &__img-wrapper {
      img {
        width: 40px;
        height: 45px;
      }
    }
    &__feedback {
      p {
        font-size: 0.875rem;
      }
      .btn {
        font-size: 0.875rem;
      }
    }
  }
}