.main {
	position: relative;
	min-height: 565px;
	width: 100%;
	height: 100%;
	&__bg {
		overflow: hidden;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		width: 100%;
		z-index: -1;
		&::before {
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			background: rgba(#000, 0.52);
		}
		video.main__bg__video {
			width: 100%;
            height: 100%;
			object-fit: cover;
		}
	}
	&__inner {
		position: absolute;
		top: 50%;
		left: 50%;
		max-width: 870px; 
		text-align: center;
		transform: translate(-50%, -50%);
		z-index: 2;
		h2 {
			font-size: 2.25rem;
			color: $secondary-color;
		}
		.btn_info {
			margin-top: 55px;
			padding: 18px 60px;
			color: #000;
			font-size: 1.375rem;
		}
	}
	&__search {
		margin-bottom: 70px;
	}
}

@include lg-down {
	.main {
		min-height: 500px;
	}
}

@include md-down {
  .main {
    &__inner {
      max-width: 750px;
      h2 {
        font-size: 2rem;
      }
    }
    &__search {
      margin-bottom: 50px;
    }
  }
}

@include sm-down {
	.main {
		&__bg {
			background-image: url(../img/bg/bg-main.jpg);
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center center;
			video {
				display: none;
			}
		}
		&__inner {
			h2 {
				font-size: 1.5rem;
				line-height: 1.625rem;
			}
			.btn_info {
				font-size: 1.25rem;
			}
		}
	}
}
