.footer {
  padding: 50px 0 30px 0;
  background-color: #191818;
  color: $secondary-color;
  &__main {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    nav {
      display: flex;
      justify-content: flex-start;
      ul {
        width: 250px;
        margin-right: 10px;
        li {
          margin-bottom: 5px;
          a {
            line-height: 1.5rem;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
  &__social {
    font-size: 1.25rem;
    line-height: 1.5rem;
    ul {
      display: flex;
      margin-top: 20px;
      li {
        margin-right: 10px;
        &:last-of-type {
          margin-right: 0;
        }
        a {
          font-size: 0px;
          width: 1px;
          height: 1px;
        }
        img {
          width: 35px;
          height: 35px;
        }
      }
    }
  }
  &__copy {
    font-size: 0.75rem;
    line-height: 1.5rem;
    a {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
}

@include md-down {
  .footer {
    padding: 50px 0 40px 0;
    &__main {
      margin-bottom: 25px;
      nav {
        width: 100%;
        flex-direction: row-reverse;
        justify-content: flex-end;
        ul {
          width: 215px;
        }
      }
    }
    &__social {
      margin-top: 30px;
    }
  }
}

@include md-down {
  .footer {
    &__main {
      nav {
        flex-direction: column;
        ul {
          width: 100%;
          margin-bottom: 30px;
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }
    &__social {
      margin-top: 30px;
    }
  }
}