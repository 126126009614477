.registr-block {
  padding: 60px 0 45px 0;
  background-color: rgba(#5e2a41, 0.09);
  &__head {
    margin: 0 auto;
    max-width: 750px;
    text-align: center;
    h2 {
      font-size: 1.875rem;
    }
    a {
      border-bottom: 1px solid $base-color;
      padding-bottom: 2px;
      &:hover {
        border-bottom: none;
      }
    }
  }
  &__form {
    margin: 30px auto 0 auto;
    max-width: 480px;
    .form {
      &__field {
        margin-bottom: 20px;
      }
      &__action {
        .btn_info {
          padding: 16px 20px;
          width: 100%;
          font-size: 1.375rem;
          color: $secondary-color;
        }
      }
    }
  }
}

@include md-down {
  .registr-block {
    padding: 45px 0 50px 0;
    &__form {
      max-width: 380px;
      .form {
        &__field {
          .input {
            padding: 16px 22px;
          }
        }
        &__action {
          .btn_info {
            padding: 13px 20px;
          }
        }
      }
    }
  }
}

@include sm-down {
  .registr-block {
    &__head {
      h2 {
        font-size: 1.5rem;
      }
    }
  }
}