/* Additional files from base */
@import 'base/reset.scss';
@import 'base/fonts.scss'; 
@import 'base/variables.scss';
@import 'base/mixin.scss';
@import 'base/default.scss';

/* Additional files from components */
@import 'components/_reviews-section.scss';
@import 'components/_rating.scss';
@import 'components/_registr-block.scss';
@import 'components/_pop-categories.scss';
@import 'components/_select.scss';
@import 'components/_bread-crumbs.scss';
@import 'components/_categories-list.scss';
@import 'components/_paging.scss';
@import 'components/_previews.scss';
@import 'components/_banners.scss';
@import 'components/_profile.scss';
@import 'components/_obj.scss';

/* Additional files from parts */
@import 'parts/header.scss';
@import 'parts/footer.scss';

/* Additional files from pages */
@import 'pages/_index.scss';
@import 'pages/_categories.scss';
@import 'pages/_template.scss';
@import 'pages/_add-obj-ct.scss';
@import 'pages/_person-profile.scss';
@import 'pages/_add-obj-in.scss';
@import 'pages/_users.scss';
@import 'pages/_authorization.scss';
@import 'pages/_object.scss';
@import 'pages/_object-det.scss';
@import 'pages/_add-comment.scss';