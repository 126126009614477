.paging {
  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    li {
      position: relative;
      margin-right: 10px;
      a {
        color: #4d4d4d;
        font-size: 1.125rem;
      }
      &:last-child {
        margin-right: 0;
        margin-left: 5px;
        a {
          position: relative;
          display: inline-block;
          height: 18px;
          width: 6px;
          img {
            object-fit: cover;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
    .current {
      color: #000000;
    }
  }
}