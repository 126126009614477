.reviews {
	padding-bottom: 75px;
	&__head {
		border-bottom: 1px solid  #5e2a41;
	}
    &__items {
      margin-top: 25px;
      &__banners {
        text-align: center;
        margin: 10px 0 30px 0;
      }
      &__more {
        text-align: right;
        a {
          font-size: 0.9375rem;
          text-decoration: underline;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
	&__item {
		display: flex;
		justify-content: space-between;
		margin-bottom: 25px;
		padding: 30px 10px;
		box-shadow: 0 0 7px 1px rgba(0, 0, 0, 0.03);
		&__img-wrapper {
			width: 15%;
            text-align: center;
		}
		&__info-wrapper {
			width: 85%;
			max-width: 790px;
			margin-right: auto;
			padding-left: 5px;
			h3 {
				margin-bottom: 6px;
				padding-bottom: 2px;
				font-size: 1.5rem;
				line-height: 1.8rem;
                font-weight: 700;
				a:hover {
					border-bottom: 1px solid $base-color;
				}
			}
			p {
				padding-top: 20px;
			}
		}
      &__static {
        display: flex;
        align-items: baseline;
        &__feedback {
          display: flex;
          align-items: center;
          margin-left: 5px;
          a {
            font-size: 0.875rem;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
      &__buttons-wrapper {
        margin-top: 20px;
        .btn {
          padding: 10px 18px;
          font-size: 0.9375rem;
          &:first-of-type {
            margin-right: 10px;
          }
        }
      }
	}
}
.pop-reviews {
	padding-top: 75px;
}

@include md-up {
  .reviews {
    &__items {
      &__banners {
        display: none;
      }
    }
  }
}

@include md-down {
  .reviews {
    &__item {
      padding: 20px 8px;
      &__img-wrapper {
        width: 20%;
      }
      &__info-wrapper {
        width: 80%;
        padding-left: 10px;
        h3 {
          font-size: 1.25rem;
        }
        p {
          padding-top: 12px;
        }
      }
      &__buttons-wrapper {
        margin-top: 15px;
        display: flex;
        flex-direction: column;
        .btn {
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 165px;
          height: 30px;
          margin-bottom: 8px;
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

@include md-down {
  .reviews {
    &__item {
      &__img-wrapper {
        width: 20%;
      }
      &__info-wrapper {
        width: 80%;
        padding-left: 10px;
        h3 {
          font-size: 1.125rem;
          line-height: 1.2rem;
        }
        p {
          font-size: 0.75rem;
        }
      }
      &__buttons-wrapper {
        margin-top: 15px;
        display: flex;
        flex-direction: column;
        .btn {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 165px;
          height: 30px;
          margin-bottom: 8px;
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}