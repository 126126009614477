.template {
  padding: 40px 0 70px 0;
  &__inner {
    display: flex;
    justify-content: space-between;
  }
  &__content {
    width: 74%;
    h3 {
      margin-top: 30px;
      font-size: 1.5rem;
    }
    p {
      margin: 15px 0 20px 0;
      line-height: 1.25rem;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    ul {
      padding: 15px 50px 10px 25px;
      li {
        position: relative;
        margin-bottom: 10px;
        padding-left: 5px;
        &::before {
          position: absolute;
          content: '';
          display: inline-block;
          top: 8px;
          left: -10px;
          width: 7px;
          height: 7px;
          border: 1px dashed #252525;
          background-color: #5e2a41;
          border-radius: 100%;
        }
      }
    }
  }
  &__items {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 30px 0;
    &__item {
      max-width: 270px;
      margin: 0 5px;
      text-align: center;
      img {
        width: 54px;
        height: 54px;
      }
      p {
        margin-top: 5px;
        font-weight: 700;
        line-height: 1.125rem;
      }
    }
  }
}

@include md-down {
  .template {
    &__content {
      width: 100%;
      ul {
        padding: 15px 0 10px 25px;
      }
    }
    &__items {
      flex-direction: column;
      &__item {
        margin-bottom: 30px;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
}