.add-obg-in {
  padding: 60px 0 70px 0;
  &__head {
    h2 {
      font-size: 1.875rem;
      margin-bottom: 15px;
    }
    h3 {
      font-size: 1.25rem;
      font-weight: 700;
    }
  }
  &__form {
    margin-top: 55px;
    .form {
      display: flex;
      justify-content: space-between;
      &__fields {
        width: 50%;
      }
      &__add {
        width: 50%;
      }
      &__action {
        margin-top: 40px;
        .btn {
          padding: 12px 55px;
          font-size: 1.065rem;
        }
      }
      &__field {
        margin-bottom: 35px;
        .input::placeholder {
          color: #929292;
        }
        &:last-of-type {
          margin-bottom: 0;
        }
        &__title {
          margin-bottom: 20px;
          p {
            margin-bottom: 10px;
            font-size: 1.25rem;
            font-weight: 700;
          }
        }
        &__add {
          display: flex;
          align-items: center;
          padding: 0 20px;
          height: 42px;
          max-width: 339px;
          border-radius: 10px;
          border: 1px solid #808080;
          background-color: #e1e1e1;
          cursor: pointer;
        }
      }
    }
  }
}

@include md-down {
  .add-obg-in {
    padding: 50px 0 70px 0;
    &__form {
      .form {
        flex-direction: column;
        align-items: flex-start;
        &__fields {
          max-width: 339px;
          width: 100%;
        }
        &__add {
          margin-top: 45px;
          max-width: 339px;
          width: 100%;
        }
      }
    }
  }
}

