.users {
  padding: 40px 0 70px 0;
  &__head {
    margin-bottom: 25px;
    .main-title {
      font-size: 1.875rem;
      line-height: 1.9rem;
    }
  }
  &__content {
    display: flex;
    margin-top: 30px;
  }
  &__items {
    width: 74%;
  }
  &__item {
    display: flex;
    justify-content: space-between;
    padding: 20px 5px 25px 15px;
    box-shadow: 0 0 7px 1px rgba(0, 0, 0, 0.03);
    margin-bottom: 20px;
    &:last-of-type {
      margin-bottom: 0;
    }
    &__img-wrapper {
      width: 15%;
      text-align: center;
      img {
        width: 76px;
        height: 82px;
      }
    }
    &__info-wrapper {
      width: 85%;
      display: flex;
      padding-left: 5px;
    }
    &__about {
      margin-right: 45px;
      h3 {
        font-size: 1.125rem;
        font-weight: 700;
        margin-bottom: 24px;
        a {
          &:hover {
            text-decoration: underline;
          }
        }
      }
      span {
        font-size: 0.875rem;
      }
    }
    &__static {
      display: flex;
      &__rep {
        margin-right: 45px;
        span {
          color: #006f0a;
        }
      }
    }
  }
}

@include md-down {
  .users {
    &__items {
      width: 100%;
    }
    &__item {
      &__img-wrapper {
        width: 18%;
      }
      &__info-wrapper {
        width: 82%;
        padding-left: 10px;
        padding-top: 10px;
      }
      &__about {
        margin-right: 14px;
        h3 {
          margin-bottom: 15px;
        }
      }
      &__static {
        flex-direction: column;
        &__rep {
          margin-bottom: 15px;
        }
      }
    }
  }
}

@include sm-down {
  .users {
    &__item {
      padding: 10px 5px 10px 10px;
      &__img-wrapper {
        width: max-content;
        max-width: 110px;
        img {
          width: 93px;
          height: 100px;
        }
      }
      &__info-wrapper {
        flex-direction: column;
        flex-grow: 1;
        padding-top: 0;
        width: max-content;
      }
      &__about {
        margin-right: 0;
        h3 {
          margin-bottom: 0;
        }
      }
      &__static {
        margin-top: 18px;
        &__rep {
          margin-bottom: 5px;
        }
      }
    }
  }
}