.pop-categories {
  padding: 80px 0 70px 0;
  &__head {
    text-align: center;
    margin-bottom: 25px;
  }
  &__items {
    a {
      margin-right: 12px;
      font-size: 0.95rem;
      line-height: 1.5rem;
      &:last-of-type {
        margin-right: 0;
      }
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

@include md-down {
  .pop-categories {
    padding: 55px 0 25px 0;
    &__head {
      margin-bottom: 20px;
    }
    &__items {
      text-align: justify;
    }
  }
}