.person-profile {
  .profile {
    &__main {
      &__img-wrapper {
        position: relative;
        a {
          opacity: 0;
          visibility: hidden;
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          color: $secondary-color;
          background: rgba(#000000, 0.35);
          transform: scale(0);
          transition: all 0.2s ease-out;
        }
        &:hover {
         a {
            opacity: 1;
            visibility: visible;
            transform: scale(1);
          }
        }
      }
      &__info-wrapper {
        width: 45%;
      }
      &__dop {
        width: 35%;
        &__views {
          .icon {
            width: 16px;
            height: 10px;
          }
        }
        &__bonus {
          margin-top: 78px;
          p {
            a {
              color: $dop-color;
              border-bottom: 1px solid $dop-color;
              &:hover {
                border-bottom: none;
              }
            }
          }
          span {
            display: inline-block;
            margin-top: 10px;
            font-size: 0.75rem;
            a {
              border-bottom: 1px solid $base-color;
              &:hover {
                border-bottom: none;
              }
            }
          }
        }
      }
    }
  }
}

@include md-down {
  .person-profile {
    .profile {
      &__main {
        &__img-wrapper {
          width: 30%;
        }
        &__info-wrapper {
          width: 70%;
        }
        &__rep {
          margin-top: 0;
        }
        &__dop {
          width: 100%;
          margin-top: 10px;
          &__views {
            display: none;
          }
          &__bonus {
            margin-top: 0;
          }
        }
      }
    }
  }
}

@include sm-down {
  .person-profile {
    .profile {
      &__main {
        padding: 10px 10px 20px 15px;
        &__img-wrapper {
          width: max-content;
          max-width: 110px;
        }
        &__info-wrapper {
          flex-grow: 1;
        }
        &__cap {
          h2 {
            font-size: 1.25rem;
            line-height: 1.3rem;
          }
          &__prev {
            span {
              font-size: 1rem;
            }
          }
        }
        &__info-wrapper {
          padding-left: 15px;
          &__views {
            display: none;
          }
        }
        &__rep {
          margin-top: 0;
        }
        &__dop {
          width: 100%;
          margin-top: 10px;
          &__views {
            display: block;
          }
          &__bonus {
            margin-top: 10px;
          }
        }
      }
    }
  }
}