.categories-reviews {
  &__head {
    margin-bottom: 20px;
  }
  &__sort {
    a {
      display: inline-block;
      margin-right: 25px;
    }
    .active {
      text-decoration: underline;
    }
  }
  &__content {
    margin: 60px 0 70px 0;
    display: flex;
    justify-content: space-between;
  }
  .reviews__items {
    width: 76%;
    margin-top: 0;
  }
  .reviews__item {
    &__img-wrapper {
      width: 25%;
    }
    &__info-wrapper {
      width: 75%;
    }
    &__buttons-wrapper {
      display: flex;
      .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        width: 160px;
        height: 38px;
      }
    }
  }
}

@include md-down {
  .categories-reviews {
    &__content {
      margin: 55px 0 60px 0;
    }
    .reviews__items {
      width: 100%;
      padding-right: 0;
    }
    .reviews__item {
      &__img-wrapper {
        width: 25%;
      }
      &__info-wrapper {
        width: 75%;
      }
      &__buttons-wrapper {
        flex-direction: column;
      }
    }
  }
}

@include sm-down {
  .categories {
    &-reviews {
      margin-top: 30px;
      &__sort {
        a {
          margin-bottom: 5px;
        }
      }
    }
  }
}