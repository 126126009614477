.previews {
  width: 26%;
  padding-left: 30px;
  &__head {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }
  &__item {
    margin-bottom: 30px;
    padding: 20px 10px;
    border: 1px solid #b7b7b7;
    &__main{
      display: flex;
      margin-bottom: 5px;
      &__img-wrapper {
        width: 25%;
        img {
          max-height: 90px;
        }
      }
      &__title {
        width: 75%;
        h3 {
          font-size: 1rem;
          font-weight: 700;
          a {
            &:hover {
              color: $dop-color;
            }
          }
        }
        .reviews__item__static {
          margin-top: 10px;
          align-items: center;
          .rating {
            li {
              width: 12px;
              height: 12px;
            }
          }
          &__feedback {
            a {
              font-size: 0.75rem;
            }
          }
        }
      }
    }
    &__desc {
      font-size: 0.875rem;
    }
    &__buttons-wrapper {
      margin-top: 15px;
      .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        margin-bottom: 10px;
        width: 164px;
        height: 30px;
        font-size: 0.9375rem;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
}

@include md-down {
  .previews {
    display: none;
  }
}