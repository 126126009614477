.obj {
  display: flex;
  width: 76%;
  .icon {
    vertical-align: middle;
    left: 0;
    top: 0;
    width: 15px;
    height: 15px;
    margin-right: 5px;
  }
  &__img-wrapper {
    width: 30%;
  }
  &__info {
    width: 70%;
    h3 {
      margin-bottom: 15px;
      font-size: 1.875rem;
      font-weight: 700;
    }
    span {
      display: inline-block;
      margin-top: 14px;
      font-size: 0.875rem;
    }
    &__feedback {
      margin-top: 9px;
      a {
        font-size: 0.875rem;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    &__buttons-wrapper {
      margin-top: 30px;
      .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        width: 203px;
        height: 39px;
        color: $secondary-color;
        background: #ff7124;
        border-radius: 14px;
        &:hover {
          background: darken(#ff7124, 5%);
          transition: all 0.2s;
        }
      }
    }
    &__dop {
      margin-top: 20px;
      a {
        display: inline-block;
        margin-bottom: 15px;
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
      &__spec {
        color: $dop-color;
      }
      &__links {
        display: flex;
        flex-direction: column;
      }
    }
  }
  &__links {
    display: none;
  }
}

@include md-down {
  .obj {
    width: 100%;
    &__img-wrapper {
      width: 35%;
    }
    &__info {
      width: 65%;
      h3 {
        font-size: 1.5rem;
        margin-bottom: 7px;
      }
      &__buttons-wrapper {
        margin-top: 23px;
      }
      &__dop {
        &__links {
          display: none;
        }
      }
    }
    &__links {
      width: 100%;
      display: flex;
      justify-content: space-between;
      a {
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
      &__spec {
        display: none;
        a {
        }
      }
    }
  }
}

@include sm-down {
  .obj {
    &__info {
      h3 {
        font-size: 1.25rem;
      }
      &__buttons-wrapper {
        margin-top: 15px;
        .btn {
          width: 185px;
        }
      }
      &__dop {
        display: none;
      }
    }
    &__links {
      flex-direction: column;
      margin-top: 25px;
      font-size: 0.875rem;
      a {
        margin-bottom: 15px;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
      &__spec {
        display: inline-block;
        color: $dop-color;
        font-size: 1rem;
      }
    }
  }
}