.bread-crumbs {
  margin-top: 26px;
  ul {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    li {
      position: relative;
      margin-right: 18px;
      font-size: 0.875rem;
      a {
        color: #727272;
      }
      &:last-child {
        margin-right: 0;
        &::after {
          display: none;
        }
      }
      &::after {
        content: '/';
        position: absolute;
        top: 0;
        right: -12px;
      }
    }
  }
}
