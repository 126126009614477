.add-cm {
  padding: 60px 0 80px 0;
  h3 {
    font-size: 1.875rem;
    font-weight: 700;
  }
  &__main {
    display: flex;
    margin-top: 20px;
    &__img-wrapper {
      width: 25%;
      img {
        object-fit: contain;
      }
    }
    &__estimate {
      width: 75%;
      &__title {
        font-size: 1.25rem;
        font-weight: 700;
      }
      &__general {
        .star-rating {
          margin-top: 9px;
        }
      }
      &__dop {
        margin-top: 40px;
        &__item {
          display: flex;
          align-items: center;
          margin-top: 15px;
        }
        .dop-rating {
          margin-right: 10px;
        }
      }
    }
  }
  &__desc {
    padding: 60px 0 80px 0;
    max-width: 635px;
    &__action {
      margin-top: 45px;
      label {
        font-size: 0.875rem;
      }
      .btn {
        margin-top: 20px;
        font-size: 1.125rem;
      }
    }
    &__field {
      margin-bottom: 55px;
      &_adv {
        p {
          color: #087400;
        }
      }
      &_disadv {
        p {
          color: #a81b1b;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
      &__title {
        margin-bottom: 15px;
        p {
          font-size: 1.25rem;
          font-weight: 700;
          margin-bottom: 5px;
        }
      }
      .input {
        max-width: 100%;
      }
      textarea {
        height: 210px;
      }
      &__item {
        label {
          position: relative;
          display: inline-block;
          cursor: pointer;
          &::before {
            content: '';
            position: relative;
            display: inline-block;
            float: left;
            margin-right: 5px;
            width: 15px;
            height: 15px;
            background-color: $secondary-color;
            border: 1px solid #707070;
          }
        }
        input[type="radio"]:checked + label {
          &::after {
            content: '';
            position: absolute;
            top: 4px;
            left: 4px;
            width: 7px;
            height: 4px;
            border: 1px solid #707070;
            border-top: none;
            border-right: none;
            transform: rotate(-45deg);
          }
        }
      }
    }
  }
}

@include md-down {
  .add-cm {
    h3 {
      font-size: 1.5rem;
      line-height: 1.5rem;
    }
    &__main {
      &__img-wrapper {
        width: 35%;
      }
      &__estimate {
        width: 65%;
      }
    }
    &__desc {
      padding: 40px 0;
      &__action {
        margin-top: 35px;
        label {
          font-size: 0.75rem;
        }
        .btn {
          margin-top: 20px;
          font-size: 1rem;
        }
      }
      &__field {
        &__item {
          label {
            &::before {
              width: 15px;
              height: 15px;
            }
          }
        }
      }
    }
  }
}

@include sm-down {
  .add-cm {
    padding: 30px 0 45px;
    h3 {
      font-size: 1.125rem;
    }
    &__main {
      margin-top: 13px;
      &__img-wrapper {
      }
      &__estimate {
        &__title {
          font-size: 0.85rem;
        }
        &__dop {
          margin-top: 20px;
          &__item {
            flex-direction: column;
            align-items: flex-start;
            font-size: 0.75rem;
          }
        }
      }
    }
    &__desc {
      &__field {
        margin-bottom: 30px;
        &__item {
          font-size: 0.875rem;
        }
      }
    }
  }
}