/* Settings for site */
* {
	box-sizing: border-box;
	flex-wrap: wrap;
	font-family: $base-font;
} 
 
html {
	font-size: 16px;
}

body {
	display: flex;
	flex-direction: column;
	min-height: 100%;
	min-width: 320px;
	color: $base-color;
	font-weight: 400;
	line-height: 1.1;
	letter-spacing: 0.01em;
	footer {
		width: 100%;
		margin-top: auto;
	}
}

img {
	max-width: 100%;
}

ul {
	list-style: none;
	padding: 0;
	margin: 0; 
}

video {
  pointer-events: none;
}

button, input {
	background: none;
	border: none;
}

input[type="checkbox"] {
	position: relative;
	display: block;
	top: 15px;
	left: 0;
	height: 5px;
	width: 5px;
	opacity: 0;
}

input[type="radio"] {
	position: relative;
	display: block;
	top: 15px;
	left: 0;
	height: 5px;
	width: 5px;
	opacity: 0;
}

input[type="file"] {
	position: absolute;
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	z-index: -1;
}

textarea {
	resize: none;
	overflow-y: auto;
}

a {
	color: inherit;
	text-decoration: none;
	transition: all 0.2s;
}

h1, h2, h3, h4, h5, h6 {
	font-weight: 300;
}

svg {
	fill: currentColor;
}

.svg-sprite {
	display: none;
}


.container {
  max-width: 1170px;
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}

@include md-down {
  .container {
    max-width: 1024px;
  }
}

@include sm-down {
  .container {
    max-width: 768px;
  }
}

@include xs-down {
  .container {
    max-width: 576px;
  }
}
.main-title {
	font-size: 2.25rem;
	line-height: 2.8rem;
}

@include sm-down {
	.main-title {
		font-size: 1.625rem;
		line-height: 1.8rem;
	}
}

.btn {
	display: inline-block;
	border-radius: 14px;
	padding: 10px 19px;
	transition: all 0.2s;
	&_info { 
		background: $info-color;
		&:hover {
			background: darken($info-color, 8%);
		}
	}
	&_primary {
		border: 1px solid $info-color;
		&:hover {
			border: 1px solid lighten($effect-color, 5%);
			background: lighten($effect-color, 5%);
		}
	}
	&_search {
		font-size: 1.25rem;
		color: $secondary-color;
		background: $effect-color;
		&:hover {
			background: lighten($effect-color, 5%);
		}
	}
}

.input {
	padding: 19px 22px;
	width: 100%;
	background: $secondary-color;
	font-size: 1.125rem;
	color: $base-color;
	border-radius: 10px;
	&::placeholder {
		color: $base-color;
	}
	&_primary {
		padding: 10px 18px;
		max-width: 339px;
		height: 40px;
		font-size: 1rem;
		border: 1px solid #808080;
	}
}

.form {
	&__field {
		&-check {
			margin-top: 12px;
			label {
				position: relative;
				display: inline-block;
				font-size: 0.8125rem;
				cursor: pointer;
				&::before {
					content: '';
					position: relative;
					display: inline-block;
					float: left;
					margin-right: 5px;
					width: 15px;
					height: 15px;
					background-color: $secondary-color;
					border: 1px solid #707070;
				}
			}
			a {
				text-decoration: underline;
				&:hover {
					text-decoration: none;
				}
			}
			input[type="checkbox"]:checked + label {
				&::after {
					content: '';
					position: absolute;
					top: 4px;
					left: 4px;
					width: 7px;
					height: 4px;
					border: 1px solid #707070;
					border-top: none;
					border-right: none;
					transform: rotate(-45deg);
				}
			}
		}
	}
}

.form {
	&-search {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 48px;
		border-radius: 10px;
		.form {
			&__fields {
				flex-grow: 1;
				display: flex;
				max-width: 100%;
				height: 100%;
				background: $secondary-color;
				border-radius: 10px;
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
			}
			&__field {
				&_search {
					flex-grow: 1;
				}
				.input_search {
					padding: 0 20px;
					height: 100%;
				}
				.select-wrapper {
					position: relative;
					width: 160px;
					&::before {
						content: '';
						position: absolute;
						height: 34px;
						top: 50%;
						left: 0;
						transform: translateY(-50%);
						border-left: 1px dashed #959595;
					}
				}
			}
			&__action {
				height: 100%;
				.btn_search {
					max-width: 100%;
					height: 100%;
					width: 130px;
					border-radius: 10px;
					border-top-left-radius: 0;
					border-bottom-left-radius: 0;
				}
			}
		}
	}
}

.form__field-select {
	.select-wrapper_primary {
		border-radius: 10px;
		border: 1px solid #808080;
		width: 339px;
		height: 42px;
		.cs-select {
			> span {
				justify-content: flex-start;
				color: #929292;
			}
		}
	}
}

@include md-down {
  .form {
    &-search {
      .form {
        &__action {
          .btn_search {
            width: 40px;
            background-image: url(../img/icons/icon-search.png);
            background-repeat: no-repeat;
            background-size: 22px;
            background-position: 50% 50%;
            font-size: 0;
            color: transparent;
          }
        }
      }
    }
  }
}

@include sm-down {
  .form {
    &-search {
      .form {
        &__fields {
			width: 80%;
          &_active {
          }
        }
        &__field {
          &_search {
			  width: 45%;
          }
          .input_search {
			  padding: 0 10px;
			  font-size: 0.75rem;
          }
          .select-wrapper {
			  font-size: 0.75rem;
			  max-width: 120px;
			  .cs-skin-border {
				  &::after {
					  right: 10px;
				  }
			  }
          }
        }
        &__action {
          .btn_search {
            width: 40px;
            background-image: url(../img/icons/icon-search.png);
            background-repeat: no-repeat;
            background-size: 22px;
            background-position: 50% 50%;
            font-size: 0;
            color: transparent;
          }
        }
      }
    }
  }
}

.star-rating {
	display: flex;
	flex-direction: row-reverse;
	justify-content: flex-end;
	&__input {
		display: none;
		margin: 0;
	}
	&__item {
		width: 18px;
		height: 18px;
		background-image: url(../img/icons/icon-star.png);
		background-repeat: no-repeat;
		background-size: 100% 100%;
		cursor: pointer;
	}
}
.star-rating__item:hover:before,
.star-rating__item:hover ~ .star-rating__item:before,
.star-rating__input:checked ~ .star-rating__item:before {
	content: "";
	padding: 0px 18px;
	background-image: url(../img/icons/icon-star_active.png);
	background-repeat: no-repeat;
	background-size: 100% 100%;
}
.dop-rating {
	display: flex;
	flex-direction: row-reverse;
	justify-content: flex-end;
	&__input {
		display: none;
		margin: 0;
	}
	&__item {
		position: relative;
		margin-right: 2px;
		width: 15px;
		height: 15px;
		border: 1px solid $effect-color;
		border-radius: 3px;
		cursor: pointer;
	}
}
.dop-rating__item:hover:before,
.dop-rating__item:hover ~ .dop-rating__item:before,
.dop-rating__input:checked ~ .dop-rating__item:before {
	content: "";
	position: absolute;
	top: -2%;
	left: -2%;
	width: 104%;
	height: 104%;
	background: $effect-color;
}

@include md-down {
  .star-rating__item:hover:before,
  .star-rating__item:hover ~ .star-rating__item:before,
  .star-rating__input:checked ~ .star-rating__item:before {
    background-size: contain;
    margin-left: -2px;
  }
	.star-rating {
		&__item {
			width: 14px;
			height: 14px;
		}
	}
	.dop-rating {
		&__item {
			width: 12px;
			height: 12px;
		}
	}
}

@include sm-down {
	.dop-rating {
      margin-bottom: 5px;
	}
}