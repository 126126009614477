.authorization {
  padding: 65px 0 60px 0;
  &__wrapper {
    margin: 0 auto;
    max-width: 556px;
  }
  &__head {
    margin-bottom: 30px;
    display: flex;
    a {
      position: relative;
      padding-bottom: 7px;
      width: 50%;
      text-align: center;
      font-size: 1.875rem;
      border-bottom: 1px solid #959595;
    }
    .active {
      &::before {
        content: '';
        position: absolute;
        bottom: -2px;
        left: 0;
        width: 100%;
        height: 3px;
        border: 1px dashed #252525;
        background-color: #644050;
      }
    }
  }
  &__forms {
    .form {
      display: none;
      &__fields {
        a {
          display: inline-block;
          margin-top: 15px;
          height: 1.125rem;
          border-bottom: 1px solid #5e5e5e;
          &:hover {
            border-bottom: none;
          }
        }
      }
      &__field {
        margin-bottom: 25px;
        &:last-of-type {
          margin-bottom: 0;
        }
        .input_primary {
          max-width: 100%;
        }
      }
      &__action {
        max-width: 360px;
        margin: 30px auto 0 auto;
        .btn {
          height: 47px;
          max-width: 360px;
          width: 100%;
          font-size: 1.25rem;
        }
        &__social {
          margin-top: 25px;
          text-align: center;
          h4 {
            color: #000000;
            font-size: 1.5rem;
          }
          ul {
            margin-top: 5px;
            display: flex;
            justify-content: center;
            li {
              margin-right: 14px;
              &:last-of-type {
                margin-right: 0;
              }
              a {
                img {
                  width: 38px;
                  height: 38px;
                }
              }
            }
          }
        }
      }
    }
    .active {
      display: block;
    }
  }
}

@include sm-down {
  .authorization {
    padding: 45px 0 40px 0;
    &__head {
      margin-bottom: 20px;
      a {
        font-size: 1.25rem;
      }
    }
    &__forms {
      .form {
        &__action {
          .btn {
            font-size: 1.125rem;
          }
          &__social {
            h4 {
              margin-bottom: 10px;
              font-size: 1.25rem;
            }
          }
        }
      }
    }
  }
}