.rating {
  display: flex;
  li {
    width: 18px;
    height: 18px;
    margin-right: 5px;
    background-image: url(../img/icons/icon-star.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .rating_active {
    background-image: url(../img/icons/icon-star_active.png);
  }
  .rating_active ~ li {
    background-image: url(../img/icons/icon-star_active.png);
  }
}

@include md-down {
  .rating {
    li {
      width: 13px;
      height: 13px;
    }
  }
}