.add-obj-ct {
  padding: 60px 0;
  &__head {
    margin-bottom: 35px;
    .main-title {
      font-size: 1.875rem;
      line-height: 1.9rem;
    }
  }
  &__search {
    p {
      font-size: 1.25rem;
      font-weight: 700;
      margin-bottom: 15px;
    }
  }
  &__categories {
    margin-top: 55px;
    &__name {
      position: relative;
      margin-bottom: 40px;
      padding-left: 40px;
      font-size: 1.125rem;
      cursor: pointer;
      &:hover {
        color: $dop-color;
      }
      .icon-ct {
        position: absolute;
        left: 0;
        top: 0;
        transform: translateY(-25%);
        width: 30px;
        height: 38px;
      }
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    .active {
      margin-bottom: 20px;
      .add-obj-ct__categories__sub-list {
        display: block;
      }
    }
    &__sub-list {
      display: none;
      margin: 5px 0 0 15px;
      li {
        margin-bottom: 5px;
        a {
          font-size: 0.875rem;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

@include md-down {
  .add-obj-ct {
    padding: 50px 0;
    &__categories {
      &__name {
        margin-bottom: 30px;
      }
    }
  }
}