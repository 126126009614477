.categories-list {
  margin-top: 35px;
  ul {
    display: flex;
    align-items: center;
    li {
      margin-right: 18px;
      margin-bottom: 25px;
      &:last-of-type {
        margin-right: 0;
      }
      a {
        position: relative;
        padding-bottom: 5px;
        border-bottom: 1px solid #8b6d7a;
        &:hover {
          border-bottom: none;
          &::before {
            content: '';
            position: absolute;
            width: calc(100% + 20px);
            height: calc(100% + 10px);
            top: -5px;
            left: -10px;
            border: 1px solid $dop-color;
            border-radius: 10px;
            transition: 0.5s ease-out;
          }
        }
      }
    }
  }
}

@include sm-down {
  .categories-list {
    ul {
      li {
        margin-bottom: 20px;
        a {
          font-size: 0.875rem;
          &:hover {
            &::before {
              top: -7px;
            }
          }
        }
      }
    }
  }
}