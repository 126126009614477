@font-face {
  font-family: 'Lato';
  src: local('Lato Hairline'), local('Lato-Hairline'), url('../fonts/Lato/latohairline.woff2') format('woff2'), url('../fonts/Lato/latohairline.woff') format('woff'), url('../fonts/Lato/latohairline.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Lato';
  src: local('Lato Thin'), local('Lato-Thin'), url('../fonts/Lato/latothin.woff2') format('woff2'), url('../fonts/Lato/latothin.woff') format('woff'), url('../fonts/Lato/latothin.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Lato';
  src: local('Lato Light'), local('Lato-Light'), url('../fonts/Lato/latolight.woff2') format('woff2'), url('../fonts/Lato/latolight.woff') format('woff'), url('../fonts/Lato/latolight.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Lato';
  src: local('Lato Regular'), local('Lato-Regular'), url('../fonts/Lato/latoregular.woff2') format('woff2'), url('../fonts/Lato/latoregular.woff') format('woff'), url('../fonts/Lato/latoregular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Lato';
  src: local('Lato Medium'), local('Lato-Medium'), url('../fonts/Lato/latomedium.woff2') format('woff2'), url('../fonts/Lato/latomedium.woff') format('woff'), url('../fonts/Lato/latomedium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Lato';
  src: local('Lato Bold'), local('Lato-Bold'), url('../fonts/Lato/latobold.woff2') format('woff2'), url('../fonts/Lato/latobold.woff') format('woff'), url('../fonts/Lato/latobold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}