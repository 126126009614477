.object {
  padding: 30px 0 70px 0;
  &__main {
    display: flex;
    justify-content: space-between;
  }
  &__inner {
    display: flex;
    justify-content: space-between;
  }
  &__head {
    border-bottom: 1px solid #b7b7b7;
    margin-bottom: 28px;
    a {
      position: relative;
      display: inline-block;
      font-size: 1.125rem;
      padding: 13px 11px;
    }
    .active {
      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        transform: translateY(50%);
        width: 100%;
        height: 3px;
        background-color: #644050;
      }
    }
  }
  &__content {
    width: 76%;
  }
  &__reviews {
    display: none;
    .categories-reviews__sort {
      margin-bottom: 32px;
    }
    &__item {
      margin-bottom: 25px;
      padding: 20px 10px 20px 40px;
      box-shadow: 0 0 7px 1px rgba(0, 0, 0, 0.03);
      &:last-of-type {
        margin-bottom: 0;
      }
      &__main {
        display: flex;
      }
      &__img-wrapper {
        img {
          width: 76px;
          height: 82px;
        }
      }
      &__details {
        padding-left: 24px;
      }
      &__title {
        display: flex;
        margin-bottom: 7px;
        h3 {
          font-size: 1.125rem;
          font-weight: 700;
          margin-right: 45px;
        }
        &__rep {
          span {
            color: #006f0a;
          }
        }
      }
      &__date {
        font-size: 0.75rem;
        margin-bottom: 12px;
      }
      &__feedback {
        margin-top: 20px;
        h4 {
          font-size: 1.125rem;
          font-weight: 700;
          margin-bottom: 10px;
        }
        p {
          margin-bottom: 25px;
        }
        a {
          padding: 8px 12px;
          color: #000000;
        }
      }
    }
  }
  &__info {
    display: none;
  }
  .content_active {
    display: block;
  }
  .banners-wrapper {
    width: 24%;
    margin-top: 48px;
    .banners {
      width: 100%;
    }
    .previews {
      margin-top: 70px;
      width: 100%;
    }
  }
}

@include md-down {
  .object {
    &__head {
      margin-top: 20px;
    }
    &__content {
      width: 100%;
    }
  }
}

@include sm-down {
  .object {
    &__reviews {
      &__item {
        padding: 20px 10px;
        &__title {
          flex-direction: column;
          h3 {
            margin-right: 0;
            font-size: 1rem;
          }
          &__rep {
            margin-top: 5px;
            font-size: 0.875rem;
          }
        }
        &__date {
          margin-bottom: 10px;
        }
        &__feedback {
          margin-top: 15px;
          h4 {
            font-size: 1rem;
          }
          p {
            margin-bottom: 15px;
            font-size: 0.75rem;
          }
          a {
            font-size: 0.875rem;
          }
        }
      }
    }
  }
}