/* Start styles for cs-skin-border */

.cs-select.cs-skin-border {
  background: transparent;
}

.cs-select.cs-skin-border > span {
  color: $base-color;
}
.cs-skin-border > span:hover {
  color: $dop-color;
}

.cs-skin-border.cs-active > span {
  color: $base-color;
}

.cs-skin-border.cs-active .cs-options {
  opacity: 1;
}
/* Finish styles for */

/* Start styles for select */
.cs-select {
  position: relative;
  user-select: none;
  padding: 0;
  font-family: $base-font;
  width: 100%;
  height: 100%;
  &:focus {
    outline: none;
  }
  &::after {
    content: '';
    position: absolute;
    right: 15px;
    top: 50%;
    background: transparent;
    border: 4px solid transparent;
    border-top: 4px solid rgba($base-color, 0.8);
  }

  select {
    display: none;
  }
  span {
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
  }
  > span {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px 0 10px;
    height: 100%;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    text-align: left;
    span {
      padding: 5px 0;
      color: $base-color;
      &:hover {
        color: $dop-color;
      }
    }
  }
}
/* Options drop down list */
.cs-options {
  position: absolute;
  opacity: 1;
  visibility: hidden;
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  max-height: 125px;
  top: 100%;
  left: 0;
  padding: 10px 20px 10px 10px;
  background: $secondary-color;
  border: 1px solid #bbb;
  border-top: none;
  box-shadow: 1px 1px 10px #999;
  z-index: 9;
}

.cs-select.cs-active .cs-options {
  visibility: visible;
}

.select-wrapper {
  position: relative;
  max-width: 100%;
  height: 100%;
  .cs-select {
    height: 100%;
  }
}

.select-wrapper {
  margin-top: 0;
  .cs-select {
    height: 100%;
  }
}